@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

.video-meta {
	display: block;
	margin-bottom: 1rem;

	&__item {
		position: relative;
		margin-right: 1rem;

		&:after {
			content: " \00b7";
			position: absolute;
			font-size: 2rem;
			line-height: 1rem;
			top: 0;
			right: -0.8rem;
		}

		&:last-child:after {
			display: none;
		}
	}
}
