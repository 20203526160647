* {
  margin: 0;
}

a:link {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

hr {
  margin: 5px 0px 0px 0px;
}

.center {
  text-align: center;
}

/* Responsive styling */
@media (max-width: 768px) {
  .left-bar-optional {
    width: 85%;
    -ms-flex-direction: column;
    flex-direction: column;
    color: #212121;
    border: 2px solid #86ddf8;
    box-shadow: none;
    border-radius: 5px;
    color: #fff;
    min-width: 200px;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 10px 10px 10px 10px;
    margin-right: 15px;
    margin-left: 15px;
    margin-top: 4px;
  }

  .result-container-optional {
    display: none;
  }

  .main-container {
    min-height: calc(100vh - 263px);
  }

  footer {
    height: 263px;
  }
}
