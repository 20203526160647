// default border or outline for a11y
/* for accessibility we need outline for all inputs */

$focusBorderColor: #0A3B8A;

input:focus,
textarea:focus {
	border: 1px solid $focusBorderColor !important;
}

::placeholder {
	color: #444 !important;
	opacity: 1 !important; /* Firefox */
}

button:focus,
a:focus {
	outline: 1px solid $focusBorderColor;
	outline-offset: 3px;
}

.light-outline:focus {
	outline: 3px solid #fff;
}

.dark-outline:focus {
	outline: 3px solid $focusBorderColor;
}

button.skip-main {
	position: absolute;
	top: -9999999px;
	left: -99999999px;
	z-index: -999999;
	
}

button.skip-main:focus {
	top: 10px;
	left: 150px;
	z-index: 1;
}

.button-wrapper {
	// Borrowed from https://gist.github.com/MoOx/9137295
	padding: 0;
	margin: 0;
	background: transparent;
	border: none;
	cursor: pointer;
	overflow: visible;
	color: inherit;
	font: inherit;
	line-height: normal;

	/* Corrects font smoothing for webkit */
	-webkit-font-smoothing: inherit;
	-moz-osx-font-smoothing: inherit;

	/* Corrects inability to style clickable `input` types in iOS */
	-webkit-appearance: none;
}